<template>
  <div class="manage-job wrapper">
    <proTitle></proTitle>
    <!-- 打卡数据 -->
    <ul class="job-data flex-layout">
      <li v-for="(item, index) in manageData" :key="index" class="data-item flex-align-between">
        <div>
          <span>{{ item.name }}</span>
          <br>
          <strong>{{ item.count }}</strong>
        </div>
        <img :src="item.icon" alt="">
      </li>
    </ul>
    <!-- 工具栏 -->
    <el-form :inline="true" class="demo-form-inline"
      v-if="!hideUsertype[activeName] || (hideUsertype[activeName] && hideUsertype[activeName].indexOf(userId) < 0)">
      <div class="formInline-right flex-layout">
        <div class="formInline-right-item">
          <i class="el-icon-data-line"></i>
          <span class="csp" @click="$router.push('/job/data')">学员整体数据</span>
        </div>
        <div class="formInline-right-item">
          <i class="el-icon-circle-plus-outline"></i>
          <span class="csp" @click="createTheme">创建作业主题</span>
        </div>

      </div>
    </el-form>
    <!-- 表格 -->
    <div class="mid-bot">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column align="center" prop="id" label="序号" width="100px"></el-table-column>
        <el-table-column align="center" prop="themeName" label="作业主题名称" width="200px"></el-table-column>
        <el-table-column align="center" prop="themeType" label="主题类型"></el-table-column>
        <el-table-column align="center" prop="questCount" label="作业总数量"></el-table-column>
        <el-table-column align="center" prop="questCommitCount" label="作业提交数量"></el-table-column>
        <el-table-column align="center" prop="mean" label="平均提交比例"></el-table-column>
        <el-table-column align="center" prop="type" label="操作" width="140px" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" style="margin-left:10px" @click="goDetail(scope.row.id)">查看
            </el-button>
            <el-button
              v-if="!hideUsertype[activeName] || (hideUsertype[activeName] && hideUsertype[activeName].indexOf(userId) < 0)"
              type="text" @click="goEdit(scope.row.id)" size="small">编辑</el-button>
            <el-button
              v-if="!hideUsertype[activeName] || (hideUsertype[activeName] && hideUsertype[activeName].indexOf(userId) < 0)"
              type="text" size="small" @click="removeTheme(scope.row.id)">删除</el-button>
            <el-button type="text" size="small" style="margin-left:10px" @click="downLoad(scope.row.id)">打包下载
            </el-button>
            <el-button type="text" size="small" style="margin-left:10px" @click="subTotal(scope.row.id)">提交情况统计
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
        :limit.sync="pages.pageSize" :pageSizes="[8, 16, 24]" @pagination="handlePageChange">
      </pagination>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
import {
  mapState
} from "vuex";
export default {
  data() {
    return {
      // activeName: "",
      tableData: [],
      manageData: [{
        title: 'themeCount',
        name: '作业主题数',
        count: '0',
        icon: require("@/assets/images/teach/theme.png"),
      },
      {
        title: 'workCount',
        name: '作业总数量',
        count: '0',
        icon: require("@/assets/images/teach/job.png")
      },
      {
        title: 'testCount',
        name: '测试总数量',
        count: '0',
        icon: require("@/assets/images/teach/test.png")
      },
      {
        title: 'questCount',
        name: '问卷总数量',
        count: '0',
        icon: require("@/assets/images/teach/quest.png")
      },
      {
        title: 'commitCount',
        name: '作业提交数量',
        count: '0',
        icon: require("@/assets/images/teach/issue.png")
      }
      ],
      pages: { //分页
        currentPage: 1,
        pageSize: 8,
        total: 0,
      },
      hideUsertype: {
        238: [14549, 14550, 14551, 14552, 14553, 14554, 14555, 14556, 14557],
        248: [8855, 8856, 8857],
        251: [10074, 10075, 10076, 10077, 10078, 10079, 10080, 10081, 10082, 10083, 10084, 10085, 10086, 10087,
          10088, 10089, 10090, 10091, 10092, 10093, 10094, 10095, 10096, 10097, 10098, 10099, 10100, 10101, 10102,
          10103, 10104, 10105, 10106, 10107, 10108, 10109, 10110, 10111, 10112, 10113, 10114, 10115, 10116, 10117,
          10118, 10119, 10120, 10121, 10122, 10123, 10124, 10125, 10126, 10127, 10128, 10129, 10130, 10131, 10132,
          10133, 10134, 10135, 10136, 10137, 10138, 10139, 10140, 10141, 10142, 10143, 10144, 10145, 10146, 10147,
          10148, 10149, 10150, 10151, 10152, 10153, 10154, 10155, 10156, 10157, 10158, 10159, 10160, 10161, 10162,
          10163, 10164, 10165, 10166, 10167, 10168, 10169, 10170, 10171, 10172, 10173, 10174, 10175, 10176, 10177,
          10178, 10179, 10180, 10181, 10182, 10183, 10184, 10185, 10186, 10187, 10188],
      },
    };
  },
  computed: {
    ...mapState({
      userId: state => state.user.userInfo.id,
      activeName: state => state.user.currentProject.id
    })
  },
  watch: {
    '$store.state.user.currentProject.id': {
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.pages.currentPage = 1;
        this.getThemeList();
        this.getManageInfo();
      }
    }
  },
  methods: {
    // 翻页
    handlePageChange(data) {
      this.pages.currentPage = data.pageIndex
      this.pages.pageSize = data.pageSize
      // console.log(this.pages);
      this.getThemeList();
    },
    // 获取统计数据
    async getManageInfo(id) {
      let params = {
        secondId: this.activeName,
        userId: this.userId,
      };
      this.$Api.Form.getWorkManage(params)
        .then((value) => {
          // console.log("管理数据", value);
          // this.manageData = value.data;
          this.manageData.map(item => {
            item.count = value.data[item.title]
          })
          // console.log(this.jobData);
        })
        .catch((reason) => {
          console.log(reason);
        });
    },
    handleClick(tab, event) {
      this.pages.currentPage = 1;
      this.getThemeList();
      this.getManageInfo(this.currentProId);
    },
    // 创建作业主题
    createTheme() {
      this.$router.push({
        path: "/createTheme_copy",
        query: {
          projectId: this.activeName,
          createId: this.userId,
          breadNumber: 4
        },
      });
    },
    // 去编辑
    goEdit(scope) {
      this.$router.push({
        path: "/createTheme_copy",
        query: {
          id: scope, //作业主题id
          projectId: this.activeName, //二级项目id
          createId: this.userId, //主题创建者id
          edit: true, //是否是点击编辑按钮进入
          breadNumber: 4
        },
      });
    },
    // 删除作业主题
    removeTheme(id) {
      console.log("删除的作业主题id--", id);
      this.$confirm("是否删除该作业主题?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          this.$Api.Form.deleteTheme(id)
            .then((res) => {
              console.log(res);
              //更新作业主题列表
              this.getThemeList();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "取消成功",
          });
        });
    },
    // 查看详情
    goDetail(id) {
      this.$router.push({
        path: "/workManage",
        query: {
          projectId: this.activeName,
          themeId: id,
          createId: this.userId,
          breadNumber: 4,
        },
      });
    },
    // 获取作业主题列表
    async getThemeList() {
      let params = {
        page: this.pages.currentPage,
        limit: this.pages.pageSize,
        projectId: this.activeName,
      };
      this.$Api.Form.getThemeList(params)
        .then((res) => {
          this.pages.total = res.data.totalCount;
          res.data.list.map((item) => {
            if (
              item.questCommitCount == 0 ||
              item.userCount == 0 ||
              item.questCount == 0
            ) {
              item.mean = "0%";
            } else {
              item.mean =
                Math.round(
                  (item.questCommitCount / (item.userCount * item.questCount)) *
                  100
                ) +
                "%";
            }
            if (item.themeType == 1) {
              item.themeType = "作业";
            } else if (item.themeType == 2) {
              item.themeType = "测试";
            } else if (item.themeType == 3) {
              item.themeType = "问卷";
            } else if (item.themeType == 4) {
              item.themeType = "普通话测试"
            } else if (item.themeType == 5) {
              item.themeType = "书法前测"
            } else if (item.themeType == 6) {
              item.themeType = "书法后测"
            }
          });
          this.tableData = res.data.list;
        })
        .catch((err) => console.log(err));
    },
    // 打包下载
    downLoad(themeId) {
      window.location.href =`${axios.defaults.baseURL}/quest/front/answer/exportZip/theme/export?themeId=${themeId}`
    },
    // 提交情况统计
    subTotal(themeId) {
      window.location.href =`${axios.defaults.baseURL}/quest/front/answer/answerStatus/export?themeId=${themeId}`
    }
  },
  mounted() {
    this.getThemeList();
    this.getManageInfo();
  }
};
</script>

<style lang="less" scoped>
@import "../less/table.less";

.job-data {
  margin-top: 16px;

  .data-item {
    width: 149px;
    margin-right: 16px;
    border: 1px solid #E7E7E7;
    border-radius: 5px;
    padding: 11px 5px 11px 8px;
    font-size: 12px;

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 32px;
      background-color: rgba(0, 80, 255, .1);
      border-radius: 50%;
    }

    &:nth-child(2) img {
      background-color: rgba(253, 178, 90, .1);
    }

    &:nth-child(3) img {
      background-color: rgba(157, 119, 251, .1);
    }

    &:nth-child(4) img {
      background-color: rgba(74, 210, 255, .1);
    }

    &:nth-child(5) img {
      background-color: rgba(254, 128, 60, .1);
    }


    strong {
      font-size: 14px;
    }
  }
}

.formInline-right {
  text-align: right;
  margin-top: 22px;
  justify-content: flex-end;

  .formInline-right-item {
    margin-right: 10px;
  }

  i {
    font-size: 19px;
    vertical-align: -2px;
  }

  .el-icon-data-line+span {
    padding-left: 7px;
  }
}

.mid-bot {
  margin-top: 15px;
}

@media screen and (min-width: 1250px) {
  .job-data {
    margin-top: 20px;

    .data-item {
      padding: 16px 11px;
      font-size: 14px;

      img {
        width: 37px;
      }

      strong {
        font-size: 18px;
      }
    }
  }

  .formInline-right {
    margin-top: 30px;
  }

}
</style>